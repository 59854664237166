import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="PickEasy" link="https://github.com/pravinthan/pick-easy" bg="url('pick-easy.png')" mdxType="ProjectCard">
  A web app that allows restaurants to create a gamified loyalty and rewards
  system for their customers.
  <br />
  This project won 1st place as the best project in a course-wide competition.
  <br />
  Access it at:&nbsp;
  <a style={{
        "position": "relative",
        "zIndex": "1",
        "color": "orange"
      }} href="https://pick-easy.pravinthan.com" target="_blank">
    pick-easy.pravinthan.com
  </a>
  <br />
  Watch a demo at:&nbsp;
  <a style={{
        "position": "relative",
        "zIndex": "1",
        "color": "orange"
      }} href="https://youtu.be/GQPerJtF0yM" target="_blank">
    youtu.be/GQPerJtF0yM
  </a>
    </ProjectCard>
    <ProjectCard title="CooperaTeX" link="https://github.com/pravinthan/cooperatex" bg="url('cooperatex.png')" mdxType="ProjectCard">
  A web app for users to collaborate and edit LaTeX documents in real-time.
  {
        /* <br />
        Access it at:&nbsp;
        <a
         style="position: relative; z-index: 1; color: orange"
         href="https://cooperatex.me"
         target="_blank"
        >
         cooperatex.me
        </a> */
      }
  <br />
  Watch a demo at:&nbsp;
  <a style={{
        "position": "relative",
        "zIndex": "1",
        "color": "orange"
      }} href="https://youtu.be/YQSpjoTv2oI" target="_blank">
    youtu.be/YQSpjoTv2oI
  </a>
    </ProjectCard>
    <ProjectCard title="NBA Visualizer" link="https://github.com/pravinthan/nba-visualizer" bg="url('nba.png')" mdxType="ProjectCard">
  A web app for user-friendly NBA schedules, box scores, play-by-play data and
  more.
  <br />
  Access it at:&nbsp;
  <a style={{
        "position": "relative",
        "zIndex": "1",
        "color": "orange"
      }} href="https://nba.pravinthan.com" target="_blank">
    nba.pravinthan.com
  </a>
    </ProjectCard>
    <ProjectCard title="Java-based Bash" link="https://github.com/pravinthan/bash-shell" bg="url('bash.png')" mdxType="ProjectCard">
  Java-based bash with a large feature set
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      