import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I am currently seeking new opportunities and new challenges to improve myself`}</p>
    </blockquote>
    <p>{`I've had a passion for designing and developing software since my early high school days. From my very first Turing "Hello, world!" program to this website, I have grown my skills for over 8 years and am ready to take on bigger challenges. As always, I am continuing to learn new and upcoming technologies relevant to developers. Some of my interests include playing and watching basketball (`}<strong parentName="p">{`We the North!`}</strong>{`) and building computers.`}</p>
    <p><a parentName="p" {...{
        "href": "./Pravinthan%20Prabagaran%20Resume.pdf"
      }}>{`Here's my résumé`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      